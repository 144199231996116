@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {        
    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .horizon-center {
        margin: 0;
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .absolute-center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }    
}

body {
    background-color: #000;
    color: white;
    margin: 0;
    font-family: 'Noto Sans TC';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.section-sm {
    @apply mx-auto;
    width: 100%;
    max-width: 880px;
}

.section-md {
    @apply mx-auto;
    width: 100%;
    max-width: 1120px;
}

.section {
    @apply mx-auto;
    width: 100%;
    max-width: 1280px;
}

.section-lg {
    @apply mx-auto;
    width: 100%;
    max-width: 1440px;
}

.Roboto {
    font-family: "Roboto", sans-serif;
}

.NotoSans {
    font-family: "Noto Sans TC", sans-serif;
}

.OpenSans {
    font-family: "Open Sans", sans-serif;
}

.RussoOne {
    font-family: "Russo One", sans-serif;
}

.MetaBoom {
    flex-grow: 0;
    text-shadow: 2px 0 0 rgba(255, 82, 185, 0.6), -3px 0 0 rgba(8, 157, 198, 0.6),
        0 4px 4px rgba(0, 0, 0, 0.25);
}

.border {
    border-width: 1px;
}

.lineClamp1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.lineClamp2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.container {
	height: 100vh;
	width: 100vw;
    max-width: 430px;
}

.container-cropper {
    background-color: rgba(0, 0, 0, 0.9);
	height: 90%;
	/* padding: 10px; */
}

.cropper {
	height: 90%;
	position: relative;
}


.container-buttons {	
	height: 10%;
    color:black;
	align-items: center;
	justify-content: center;
}

/* Menu animation */
.hamburger {
    position: relative;
}

.hamburger .line {
    width: 28px;
    height: 2px;
    background-color: #fff;
    display: block;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
}

.hamburger:hover {
    cursor: pointer;
}

/* ONE */
.hamburger.is-active .line:nth-child(2) {
    opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
    background-color: #5fddff;
}

.hamburger.is-active .line:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
    background-color: #5fddff;
}

/* Menu animation */