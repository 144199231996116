/* 浮動箭頭 */
.arrow {
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: 50% 50%;
    transform: translate3d(-50%, -50%, 0);
}

.arrow-first {
    animation: arrow-movement 2s ease-in-out infinite;
}

.arrow:before,
.arrow:after {
    background: #fff;
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
}

.arrow:before {
    transform: rotate(45deg) translateX(-23%);
    transform-origin: top left;
}

.arrow:after {
    transform: rotate(-45deg) translateX(23%);
    transform-origin: top right;
}

/* 跑馬燈 */
@keyframes arrow-movement {
    0% {
        opacity: 0;
        top: 45%;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.marqueeLeft {
    display: flex;
    animation-name: marquee;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: normal;
    transform: translateX(0);
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.marqueeRight {
    display: flex;
    animation-name: marquee;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-direction: reverse;
    transform: translateX(0);
}

/* MetaBoom 閃爍 */
.flashing {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: transparent;
    cursor: default;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

.flashing:before {
    animation: flashing 2s 1s ease-out;
    border: 3px solid #A6ECFF;
}

.flashing:after {
    animation: flashing 1.5s .5s ease-in;
    border: 3px solid #A6ECFF;
}

.flashing {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: transparent;
    cursor: default;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

.flashing:before {
    animation: flashing 2s 1s ease-out;
    border: 3px solid #A6ECFF;
}

.flashing:after {
    animation: flashing 1.5s .5s ease-in;
    border: 3px solid #A6ECFF;
}

.flashing:before,
.flashing:after {
    content: "";
    width: 70%;
    height: 70%;
    display: block;
    border-radius: 50%;
    background: #A6ECFF;
    opacity: 0;
    animation-iteration-count: infinite;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

/* Hover的 */

.flashing2 {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: transparent;
    cursor: default;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

.flashing2:before {
    animation: flashing 2s 1s ease-out;
    border: 3px solid #FF52B9;
}

.flashing2:after {
    animation: flashing 1.5s .5s ease-in;
    border: 3px solid #FF52B9;
}

.flashing2 {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: transparent;
    cursor: default;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 1;
}

.flashing2:before {
    animation: flashing 2s 1s ease-out;
    border: 3px solid #FF52B9;
}

.flashing2:after {
    animation: flashing 1.5s .5s ease-in;
    border: 3px solid #FF52B9;
}

.flashing2:before,
.flashing2:after {
    content: "";
    width: 70%;
    height: 70%;
    display: block;
    border-radius: 50%;
    background: #FF52B9;
    opacity: 0;
    animation-iteration-count: infinite;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@keyframes flashing {
    0% {
        -webkit-transform: scale(.1, .1);
        opacity: 0
    }

    50% {
        opacity: 1
    }

    100% {
        -webkit-transform: scale(1.2, 1.2);
        opacity: 0
    }
}

.floating {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin: 50px auto;
    animation: float 3s infinite linear;
}

@keyframes float {
    from {
        transform: rotate(0deg) translate3d(2px, 0, 0) rotate(0deg);
    }

    to {
        transform: rotate(360deg) translate3d(2px, 0, 0) rotate(-360deg);
    }
}